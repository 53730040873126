import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dateFormat, timeFormat } from "../../../../../dynamicController";

import io from 'socket.io-client';

const QrCode = () => {

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const [qrUrl, setQrUrl] = useState("");

    useEffect(() => {
        if (location.state && location.state.url) {
            setQrUrl(location.state.url)
        }
        if (location.state && location.state.transactionId) {
            const socket = io({ auth: { transactionId: location.state.transactionId } })
            socket.on("duitnowPayment", (transactionId) => {
                history.push("/thankyou")
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="setting-page" className="user-faq">
            <div id="setting-main">
                <div id="user-header">
                    <h5><i onClick={e => history.push("/")} className="bi bi-chevron-left me-3"></i> <strong>{t("QR code")}</strong></h5>
                </div>

                <div id="user-body">
                    {
                        qrUrl ? (
                            <div className="pt-3 pb-3">
                                <h4 className="ps-3">{t("Your QR code")}:</h4>
                                <div className="text-center">
                                    <img src={qrUrl} alt="duitnow-qr" />
                                </div>
                                <div className="text-center">
                                    <a className="btn btn-primary" href={`${process.env.REACT_APP_API_URL}/api/user/downloadQr?url=${qrUrl}`} download={`duitnow_${dateFormat(new Date())}_${timeFormat(new Date())}.png`}>{t("Download")}</a>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default QrCode;