/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { checkToken } from '../../../../redux/actions/kioskActions';
import { decrypt } from '../../../../dynamicController';

import { Button } from 'react-bootstrap';
import washerImg from '../../../../assets/images/machines/paymentWasher.png';
import dryerImg from '../../../../assets/images/machines/paymentDryer.png';
import vendingImg from '../../../../assets/images/machines/vendingMachine.png';

import DryerPayment from '../payment/DryerPayment';
import WasherPayment from '../payment/WasherPayment';
import VendingPayment from '../payment/VendingPayment'

import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import HeaderLogoCleanpro from '../../../../assets/images/logo/HeaderLogoCleanpro.png';
import BackLogo from '../../../../assets/images/logo/BackLogo.png';
import UpChevron from '../../../../assets/images/logo/UpChevron.png';
import DownChevron from '../../../../assets/images/logo/DownChevron.png';
import washIcon from "../../../../assets/images/logo/WasherLogo.png";
import dryIcon from "../../../../assets/images/logo/DryerLogo.png";
import vendingIcon from "../../../../assets/images/logo/VendingLogo.png";

import './Responsive.css'

import Faq from './Faq.js';

const mySwal = withReactContent(Swal);

const Machines = ({ machineType, checkToken, data, setPage, user, theme, userLogout, setSendThis, machine, setMachine, setEndpoint }) => {

    const scrollContainerRef = useRef(null);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [outlet, setOutlet] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [duration, setDuration] = useState(0);
    const [showFaq, setShowFaq] = useState(false);

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 25000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 25000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 25000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    useEffect(() => {
        checkToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredMachines = data?.machineData?.filter(machineData => {
        switch (machineType) {
            case 'Washer':
                return machineData.type === 'Washer';
            case 'Dryer':
                return machineData.type === 'Dryer';
            case 'Vending Machine':
                return machineData.type === 'Vending';
            default:
                return false;
        }
    }) || [];

    const selectMachine = (machineNo) => {
        setLoading(true);
        setOutlet(null);
        setMachine(null);
        axios.post("/api/user/getMachine", {
            machineNo,
            outletCode: data.outletCode,
            operatorCode: data.opId,
            operatorId: data.operatorCode,
        })
            .then((res) => {
                const response = decrypt(res.data.data);
                if (response.outletStatus !== "online") {
                    mySwal.fire(t("Outlet is currently offline, please try again later"), "", "info");
                } else if (!response.online) {
                    mySwal.fire(t("Machine is currently offline, please try again later"), "", "info");
                } else if (process.env.REACT_APP_STORETYPE !== "cuci" && response.block) {
                    mySwal.fire(t("Machine is Out of Service"), "", "info");
                } else {
                    setMachine(response);
                    setOutlet({
                        machineNo,
                        outletCode: data.outletCode,
                        operatorCode: data.opId
                    });
                    setShowOverlay(true);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    };

    const getImage = (type) => {
        switch (type) {
            case 'Washer':
                return washerImg;
            case 'Dryer':
                return dryerImg;
            case 'Vending Machine':
                return vendingImg;
            default:
                return null;
        }
    };

    const serviceIcon = (type) => {
        switch (type) {
            case 'Washer':
                return washIcon;
            case 'Dryer':
                return dryIcon;
            case 'Vending Machine':
                return vendingIcon;
            default:
                return null;
        }
    }

    const serviceColor = (type) => {
        switch (type) {
            case 'Washer':
                return '#004FC1';
            case 'Dryer':
                return '#f76b8a';
            case 'Vending Machine':
                return '#222831';
            default:
                return null;
        }
    }

    return (
        <div id="machines-screen" className="d-flex flex-column align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#E3F2FF", padding: "40px", position: "relative" }}>
            <div className="header d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: "120px" }} />
                <div className="d-flex justify-content-between align-items-center" style={{ gap: "20px" }}>
                    <img
                        src={HomeCleanpro}
                        alt="Home Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    <img
                        src={FaqCleanpro}
                        alt="FAQ Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setShowFaq(!showFaq)}
                    />
                    <img
                        src={BackLogo}
                        alt="Back Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('SelectService')}
                    />
                    {showFaq && (
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                            <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                        </div>
                    )}
                </div>
            </div>

            <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                {data && (
                    <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                        {data.outlet_fullname}
                    </h2>
                )}
            </div>

            <h2 style={{ fontSize: '40px', marginBottom: '20px', marginTop: '40px', fontWeight: '600' }}>
                {t("Welcome")}{user ? `, ${user.name}` : ""}
            </h2>

            <Button
                style={{
                    width: '400px',
                    height: '150px',
                    fontSize: '45px',
                    fontWeight: '600',
                    color: '#FFFFFF',
                    backgroundColor: serviceColor(machineType),
                    borderColor: serviceColor(machineType),
                    borderRadius: '20px',
                    marginBottom: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '15px'
                }}
            >
                {t(machineType)}
                <img src={serviceIcon(machineType)} alt={machineType} style={{ marginLeft: '20px', height: '60px' }} />
            </Button>

            {loading ? (
                <div className="text-center" style={{ marginTop: '100px' }}>
                    <div className="spinner-border" style={{ color: "#004FC1", width: '100px', height: '100px' }} role="status">
                        <span className="sr-only">Loading ...</span>
                    </div>
                </div>
            ) : (
                <div ref={scrollContainerRef} style={{ overflowY: 'auto', height: '1300px', width: '980px', position: 'relative', marginRight: '-60px' }}>
                    <div className="d-flex flex-wrap justify-content-center" style={{ gap: '40px', maxWidth: '900px' }}>
                        {filteredMachines.map((machineData, i) => (
                            <div
                                key={i}
                                className="machine-card"
                                onClick={() => selectMachine(machineData.machineNo)}
                                style={{
                                    width: '420px',
                                    height: '450px',
                                    backgroundColor: '#BCE0FD',
                                    borderRadius: '20px',
                                    padding: '20px',
                                    textAlign: 'center',
                                    position: 'relative',
                                    border: machineData.isSelected ? '4px solid black' : 'none',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={getImage(machineType)}
                                    alt={machineType}
                                    style={{
                                        height: '180px',
                                        width: 'auto',
                                        marginBottom: '20px',
                                        objectFit: 'contain'
                                    }}
                                />

                                <h4 style={{ fontSize: '30px', fontWeight: '600', marginBottom: '10px' }}>{machineData.name}</h4>
                                <p style={{ fontSize: '25px', fontWeight: '500' }}>{machineData.capacity}</p>
                                <div className="d-flex justify-content-between align-items-center" style={{ width: '100%', padding: '0 20px', margin: '10px 0' }}>
                                    <span style={{
                                        backgroundColor: machineData.online ? 'green' : '#d33',
                                        padding: '20px 40px',
                                        borderRadius: '20px',
                                        color: 'white',
                                        fontSize: '25px',
                                        margin: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flex: 1,
                                        textAlign: 'center'
                                    }}>
                                        {machineData.online ? t('Online') : t('Offline')}
                                    </span>
                                    <span style={{
                                        backgroundColor: machineData.running ? '#d33' : 'green',
                                        padding: '20px 40px',
                                        borderRadius: '20px',
                                        color: 'white',
                                        fontSize: '25px',
                                        margin: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flex: 1,
                                        textAlign: 'center'
                                    }}>
                                        {machineData.running ? t('Running') : t('Vacant')}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {showOverlay && machine && machineType === "Washer" ? (
                <WasherPayment
                    machine={machine}
                    outlet={outlet}
                    user={user}
                    theme={theme}
                    setDuration={setDuration}
                    setPage={setPage}
                    userLogout={userLogout}
                    setSendThis={setSendThis}
                    setShowOverlay={setShowOverlay}
                    setEndpoint={setEndpoint}
                />
            ) : showOverlay && machine && machineType === "Dryer" ? (
                <DryerPayment
                    machine={machine}
                    outlet={outlet}
                    user={user}
                    theme={theme}
                    setDuration={setDuration}
                    setPage={setPage}
                    userLogout={userLogout}
                    setSendThis={setSendThis}
                    setShowOverlay={setShowOverlay}
                    setEndpoint={setEndpoint}
                />
            ) : showOverlay && machine && machineType === "Vending Machine" ? (
                <VendingPayment
                    machine={machine}
                    outlet={outlet}
                    user={user}
                    theme={theme}
                    setPage={setPage}
                    userLogout={userLogout}
                    setSendThis={setSendThis}
                    setShowOverlay={setShowOverlay}
                    setEndpoint={setEndpoint}
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        data: state.kiosk.data,
        theme: state.theme.theme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Machines);
