import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import ComingSoon from './cleanpro/ComingSoon';
import StartScreen from './cleanpro/StartScreen';
import Option from './cleanpro/Option';
import SelectService from './cleanpro/SelectService';
import Machines from './cleanpro/Machines';
import Payment from './cleanpro/Payment';
import ThankYou from './cleanpro/Thankyou';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const mySwal = withReactContent(Swal);

const CleanproDashboard = ({ token, outletId }) => {

    const { t } = useTranslation();

    const [page, setPage] = useState('Start');
    const [machineType, setMachineType] = useState('');
    const [user, setUser] = useState(null);
    const [sendThis, setSendThis] = useState(null);
    const [machine, setMachine] = useState(null);
    const [endpoint, setEndpoint] = useState(null);

    useEffect(() => {
        const disableZoom = (e) => {
            if (e.ctrlKey || e.metaKey || e.key === 'Meta' || e.key === 'Control') {
                e.preventDefault();
            }
        };

        const disablePinchZoom = (e) => {
            if (e.touches && e.touches.length > 1) {
                e.preventDefault();
            }
        };

        const disableContextMenu = (e) => {
            e.preventDefault();
        };

        const disableTextSelection = (e) => {
            e.preventDefault();
        };

        document.addEventListener('wheel', disableZoom, { passive: false });
        document.addEventListener('keydown', disableZoom, { passive: false });
        document.addEventListener('touchmove', disablePinchZoom, { passive: false });
        document.addEventListener('touchstart', disablePinchZoom, { passive: false });

        document.addEventListener('contextmenu', disableContextMenu, { passive: false });

        document.addEventListener('selectstart', disableTextSelection);

        return () => {
            document.removeEventListener('wheel', disableZoom);
            document.removeEventListener('keydown', disableZoom);
            document.removeEventListener('touchmove', disablePinchZoom);
            document.removeEventListener('touchstart', disablePinchZoom);
            document.removeEventListener('contextmenu', disableContextMenu);
            document.removeEventListener('selectstart', disableTextSelection);
        };
    }, []);

    // useEffect(() => {
    //     let timeoutId;
    //     let lastInteractionTime = Date.now();

    //     const checkInactivity = () => {
    //         const currentTime = Date.now();
    //         if (currentTime - lastInteractionTime > 25000) {
    //             setPage('Start');
    //         } else {
    //             timeoutId = setTimeout(checkInactivity, 25000 - (currentTime - lastInteractionTime));
    //         }
    //     };

    //     const handleUserInteraction = () => {
    //         lastInteractionTime = Date.now();
    //     };

    //     timeoutId = setTimeout(checkInactivity, 15000);

    //     window.addEventListener('mousemove', handleUserInteraction);
    //     window.addEventListener('keydown', handleUserInteraction);
    //     window.addEventListener('touchstart', handleUserInteraction);

    //     return () => {
    //         clearTimeout(timeoutId);
    //         window.removeEventListener('mousemove', handleUserInteraction);
    //         window.removeEventListener('keydown', handleUserInteraction);
    //         window.removeEventListener('touchstart', handleUserInteraction);
    //     };
    // }, [setPage]);

    const userLogout = () => {
        setUser(null);
        setPage('Start');
    };

    const handleMachineTypeSelect = (type) => {
        setMachineType(type);
        setPage('Machines');
    };

    const checkReaderStatus = () => {
        axios
            .post("/api/kiosk/paywave/checkReaderStatus", { token, outletId })
            .then((res) => {

            })
            .catch((err) => {
                mySwal.fire(t('Error'), t(err.response.data.error), 'error');
            });
    }

    return (
        <div>
            {page === 'ComingSoon' && <ComingSoon />}
            {page === 'Start' && <StartScreen setPage={setPage} checkReaderStatus={checkReaderStatus} />}
            {page === 'Option' && <Option setPage={setPage} setUser={setUser} />}
            {page === 'SelectService' && (
                <SelectService
                    onMachineTypeSelect={handleMachineTypeSelect}
                    setPage={setPage}
                    user={user}
                />
            )}
            {page === 'Machines' && (
                <Machines
                    machineType={machineType}
                    setPage={setPage}
                    user={user}
                    userLogout={userLogout}
                    setSendThis={setSendThis}
                    machine={machine}
                    setMachine={setMachine}
                    setEndpoint={setEndpoint}
                />
            )}
            {page === 'Payment' && <Payment setPage={setPage} sendThis={sendThis} machine={machine} endpoint={endpoint} />}
            {page === 'ThankYou' && <ThankYou setPage={setPage} machineType={machineType} machine={machine} />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        token: state.kiosk.token,
        outletId: state.kiosk.outletId
    };
};

export default connect(mapStateToProps, null)(CleanproDashboard);