/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import cleanprologo from '../../../../assets/images/logo/Logo_Cleanproplus-blue.png';
import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import HeaderLogoCleanpro from '../../../../assets/images/logo/HeaderLogoCleanpro.png';
import CleanproConfirmButton from '../../../../assets/images/logo/CleanproConfirmButton.png';
import CleanproBackButton from '../../../../assets/images/logo/CleanproBackButton.png';
import YesButton from '../../../../assets/images/logo/YesButton.png';
import NoButton from '../../../../assets/images/logo/NoButton.png';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from 'react-i18next';
import './Responsive.css';

import Faq from './Faq.js';

import io from 'socket.io-client';

const mySwal = withReactContent(Swal);

const Option = ({ setPage, outletId, token, setUser, data }) => {
    const { t } = useTranslation();
    const [showOverlay, setShowOverlay] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [showFaq, setShowFaq] = useState(false);
    const [qrData, setQrData] = useState(null);
    const [currentPage, setCurrentPage] = useState('Option');

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 25000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 25000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 25000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const numberRegex = /^\d+$/;
        const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

        if (!mobileNumber) {
            mySwal.fire(t("Error"), t("Please enter phone number / email address"), 'error')
        } else if (numberRegex.test(mobileNumber) && data.country === "Taiwan" && (!numberRegex.test(mobileNumber) || mobileNumber.length !== 10 || mobileNumber.substring(0, 2) !== "09")) {
            mySwal.fire({
                title: t("Error"),
                icon: "error",
                html: t("Please enter the correct phone number format. Example: 09xxxxxxxx"),
            });
        } else if (numberRegex.test(mobileNumber) && data.country === "Malaysia" && (!numberRegex.test(mobileNumber) || mobileNumber.length <= 9 || mobileNumber.length >= 12 || mobileNumber.substring(0, 2) !== "01")) {
            mySwal.fire({
                title: t("Error"),
                icon: "error",
                html: t("Please enter the correct phone number format. Example: 01xxxxxxxx"),
            });
        } else if (mobileNumber.includes("@") && !emailRegex.test(mobileNumber)) {
            mySwal.fire(t("Error"), t("Invalid Email Address"), 'error')
        } else if (!mobileNumber.includes("@") && !numberRegex.test(mobileNumber)) {
            mySwal.fire(t("Error"), t("Invalid phone number / email address"), 'error')
        } else {
            setShowOverlay(true);
        }
    };

    const handleYes = () => {
        setLoading(true);

        axios.post('/api/kiosk/checkUser', {
            token,
            outletId,
            number: mobileNumber,
        }).then(response => {

            setLoading(false);

            if (response.data.status === "EXIST") {
                setUser(response.data.data.user);
                setPage('SelectService');
            } else if (response.data.status === "NEW") {
                setUser(null)
                setQrData(response.data.data.qr);
                setCurrentPage('QRCodePage');
            }

        }).catch(err => {
            setLoading(false);
            setShowOverlay(false);
            mySwal.fire(t('Error'), t(err.response.data.error), 'error');
        });
    };

    useEffect(() => {
        if (currentPage === 'QRCodePage') {
            const newSocket = io({ auth: { transactionId: mobileNumber } });

            newSocket.on('userRegistered', (userData) => {
                setUser(userData.user);
                setPage('SelectService');
            });
            return () => {
                newSocket.disconnect();
            };
        }
    }, [currentPage]);

    if (currentPage === 'Option') {
        return (
            <div id="member-login-screen" className="d-flex flex-column justify-content-between align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#E3F2FF", padding: "40px" }}>
                <div className="header d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                    <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: "120px" }} />
                    <div className="d-flex justify-content-between align-items-center" style={{ gap: "20px" }}>
                        <img
                            src={HomeCleanpro}
                            alt="Home Icon"
                            style={{ height: '90px', cursor: 'pointer' }}
                            onClick={() => setPage('Start')}
                        />
                        <img
                            src={FaqCleanpro}
                            alt="FAQ Icon"
                            style={{ height: '90px', cursor: 'pointer' }}
                            onClick={() => setShowFaq(!showFaq)}
                        />
                    </div>
                    {showFaq && (
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                            <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                        </div>
                    )}
                </div>

                <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                    {data && (
                        <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                            {data.outlet_fullname}
                        </h2>
                    )}
                </div>

                <div className="content d-flex flex-column align-items-center" style={{ marginTop: '380px' }}>
                    <img src={cleanprologo} alt="Cleanpro Plus Logo" style={{ height: "150px", marginBottom: "20px" }} />
                    <h1 style={{ fontSize: '90px', fontWeight: '600', marginBottom: '10%' }}>{t('Member')}</h1>
                    <h4 className="text-dark" style={{ fontSize: '50px', fontWeight: '700', marginBottom: '2%', textAlign: 'center' }}>
                        {t("Please enter your mobile phone number / e-mail")}
                    </h4>
                    <Form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '850px' }}>
                        <Form.Group controlId="mobileNumber" style={{ width: '100%', marginBottom: '40px' }}>
                            <Form.Control
                                type="text"
                                inputMode="text"
                                required
                                autoComplete="off"
                                placeholder={t("Enter Your Phone Number / Email Address")}
                                style={{
                                    height: '100px',
                                    fontSize: '36px',
                                    textAlign: 'center',
                                    borderRadius: '50px',
                                    border: '2px solid #004FC1',
                                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                                }}
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                            />
                        </Form.Group>
                        <div className="buttons d-flex justify-content-center" style={{ width: '100%', gap: '20px' }}>
                            <div className="position-relative" style={{ width: '270px', height: '100px' }}>
                                <button
                                    type="submit"
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        padding: 0,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <img
                                        src={CleanproConfirmButton}
                                        alt={t("Confirm Button")}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '36px',
                                            fontWeight: '600',
                                            color: '#fff',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        {t('Confirm')}
                                    </span>
                                </button>
                            </div>

                            <div className="position-relative" style={{ width: '270px', height: '100px' }}>
                                <button
                                    type="button"
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        padding: 0,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onClick={() => setPage('Start')}
                                >
                                    <img
                                        src={CleanproBackButton}
                                        alt={t("Back Button")}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '36px',
                                            fontWeight: '600',
                                            color: '#000',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        {t('Back')}
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className="position-relative" style={{ width: "100%", height: '100px', marginTop: '100px' }}>
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    padding: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={() => {
                                    setUser(null);
                                    setPage('SelectService');
                                }}
                            >
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '36px',
                                        fontWeight: '600',
                                        color: '#004FC1',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    {t('Pay as guest')}
                                </span>
                            </button>
                        </div>
                    </Form>

                </div>

                <div className="footer text-center mt-auto">
                </div>

                {showOverlay && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                        textAlign: 'center',
                        padding: '20px'
                    }}>
                        <h2 style={{
                            color: '#000',
                            fontSize: '60px',
                            fontWeight: '600',
                            marginBottom: '50px',
                            textAlign: 'center'
                        }}>{mobileNumber.includes("@") ? t('Please verify your e-mail') : t('Please verify your number')}</h2>

                        <h1 style={{
                            color: '#000',
                            fontSize: '60px',
                            fontWeight: '500',
                            marginBottom: '50px',
                        }}>
                            {mobileNumber}
                        </h1>

                        <div className="d-flex justify-content-center position-relative" style={{ gap: '40px', marginBottom: '100px', }}>
                            <div className="position-relative" style={{ width: '250px', height: '80px' }}>
                                {loading ? (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border"
                                            style={{ color: "#004FC1" }}
                                            role="status"
                                        >
                                            <span className="sr-only">{t('Loading')}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <img
                                            src={YesButton}
                                            alt="Yes Button"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            onClick={handleYes}
                                            disabled={loading}
                                        />
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                fontSize: '36px',
                                                fontWeight: '600',
                                                color: '#fff',
                                                pointerEvents: 'none'
                                            }}
                                        >
                                            {t('Yes')}
                                        </span>
                                    </>
                                )}
                            </div>

                            <div className="position-relative" style={{ width: '250px', height: '80px' }}>
                                <img
                                    src={NoButton}
                                    alt="No Button"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onClick={() => setShowOverlay(false)}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '36px',
                                        fontWeight: '600',
                                        color: '#fff',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    {t('Cancel')}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    } else if (currentPage === 'QRCodePage') {
        return (
            <div id="qr-code-screen" className="d-flex flex-column justify-content-between align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#E3F2FF", padding: "40px" }}>
                <div className="header d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                    <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: "120px" }} />
                    <div className="d-flex justify-content-between align-items-center" style={{ gap: "20px" }}>
                        <img
                            src={HomeCleanpro}
                            alt="Home Icon"
                            style={{ height: '90px', cursor: 'pointer' }}
                            onClick={() => setPage('Start')}
                        />
                        <img
                            src={FaqCleanpro}
                            alt="FAQ Icon"
                            style={{ height: '90px', cursor: 'pointer' }}
                            onClick={() => setShowFaq(!showFaq)}
                        />
                    </div>
                    {showFaq && (
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                            <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                        </div>
                    )}
                </div>

                <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                    {data && (
                        <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                            {data.outlet_fullname}
                        </h2>
                    )}
                </div>

                <div className="content d-flex flex-column align-items-center" style={{ marginTop: '180px' }}>
                    <h4 className="text-dark" style={{ fontSize: '50px', fontWeight: '700', marginBottom: '60px', textAlign: 'center' }}>
                        {t('Scan QR to Complete your signup & access more benefits!')}
                    </h4>
                    <img src={qrData} alt="QR Code" style={{ width: '600px', height: '600px', marginBottom: '50px' }} />
                    <div className="position-relative" style={{ width: "100%", height: '100px', marginTop: '100px' }}>
                        <button
                            type="button"
                            style={{
                                background: 'none',
                                border: 'none',
                                padding: 0,
                                width: '100%',
                                height: '100%',
                            }}
                            onClick={() => {
                                setUser(null);
                                setPage('SelectService');
                            }}
                        >
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '36px',
                                    fontWeight: '600',
                                    color: '#004FC1',
                                    pointerEvents: 'none'
                                }}
                            >
                                {t('Pay as guest')}
                            </span>
                        </button>
                    </div>
                </div>
                <div className="footer text-center mt-auto">
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state) => {
    return {
        token: state.kiosk.token,
        outletId: state.kiosk.outletId,
        data: state.kiosk.data
    };
};

export default connect(mapStateToProps, null)(Option);