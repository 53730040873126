import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WasherLogo from '../../../../assets/images/logo/WasherLogo.png';
import VendingLogo from '../../../../assets/images/logo/VendingLogo.png';
import DryerLogo from '../../../../assets/images/logo/DryerLogo.png';
import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import HeaderLogoCleanpro from '../../../../assets/images/logo/HeaderLogoCleanpro.png';
import BackLogo from '../../../../assets/images/logo/BackLogo.png';
import './Responsive.css';
import Faq from './Faq.js';

const SelectService = ({ onMachineTypeSelect, setPage, data, user }) => {
    const { t } = useTranslation();
    const [showFaq, setShowFaq] = useState(false);

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 25000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 25000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 25000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    return (
        <div id="select-service-screen" className="d-flex flex-column justify-content-between align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#E3F2FF", padding: "40px" }}>
            <div className="header d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: "120px" }} />
                <div className="d-flex justify-content-between align-items-center" style={{ gap: "20px" }}>
                    <img
                        src={HomeCleanpro}
                        alt="Home Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    <img
                        src={FaqCleanpro}
                        alt="FAQ Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setShowFaq(!showFaq)}
                    />
                    <img
                        src={BackLogo}
                        alt="Back Icon"
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Option')}
                    />
                </div>
                {showFaq && (
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                        <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                    </div>
                )}
            </div>

            <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                {data && (
                    <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                        {data.outlet_fullname}
                    </h2>
                )}
            </div>

            <h2 style={{ fontSize: '40px', marginBottom: '10px', marginTop: '40px', fontWeight: '600' }}>
                {t("Welcome")}{user ? `, ${user.name}` : ""}
            </h2>

            <div className="content d-flex flex-column justify-content-center align-items-center" style={{ flexGrow: 1 }}>
                <h3 className="text-dark text-center" style={{ fontSize: '50px', fontWeight: '750', marginBottom: '60px' }}>
                    {t("Please choose your service")}
                </h3>

                <div
                    style={{
                        width: '700px',
                        height: '200px',
                        fontSize: '55px',
                        fontWeight: '700',
                        color: '#FFFFFF',
                        backgroundColor: '#004FC1',
                        borderColor: '#004FC1',
                        borderRadius: '40px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 50px',
                        marginBottom: '50px',
                    }}
                    onClick={() => onMachineTypeSelect('Washer')}
                >
                    <div style={{ textAlign: 'center', flex: 1 }}>
                        <h6 style={{ marginBottom: '10px', fontSize: '65px' }}>{t("WASHER")}</h6>
                    </div>
                    <div>
                        <img src={WasherLogo} alt="Washer Logo" style={{ height: '70px' }} />
                    </div>
                </div>

                <div
                    style={{
                        width: '700px',
                        height: '200px',
                        fontSize: '55px',
                        fontWeight: '700',
                        color: '#FFFFFF',
                        backgroundColor: '#f76b8a',
                        borderColor: '#f76b8a',
                        borderRadius: '40px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 50px',
                        marginBottom: '50px',
                    }}
                    onClick={() => onMachineTypeSelect('Dryer')}
                >
                    <div style={{ textAlign: 'center', flex: 1 }}>
                        <h6 style={{ marginBottom: '10px', fontSize: '65px' }}>{t("DRYER")}</h6>
                    </div>
                    <div>
                        <img src={DryerLogo} alt="Dryer Logo" style={{ height: '70px' }} />
                    </div>
                </div>

                <div
                    style={{
                        width: '700px',
                        height: '200px',
                        fontSize: '55px',
                        fontWeight: '700',
                        color: '#FFFFFF',
                        backgroundColor: '#222831',
                        borderColor: '#222831',
                        borderRadius: '40px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 50px',
                    }}
                    onClick={() => onMachineTypeSelect('Vending Machine')}
                >
                    <div style={{ textAlign: 'center', flex: 1 }}>
                        <h6 style={{ marginBottom: '10px', fontSize: '65px' }}>{t("VENDING MACHINE")}</h6>
                    </div>
                    <div>
                        <img src={VendingLogo} alt="Vending Logo" style={{ height: '70px' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data
    };
};

export default connect(mapStateToProps, null)(SelectService);
