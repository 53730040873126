import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dateFormat, timeFormat } from "../../../../../dynamicController";

// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

import io from 'socket.io-client';

import step1 from "../../../../../assets/images/faq/duitnow1.png";
import step2 from "../../../../../assets/images/faq/duitnow2.png";
import step3 from "../../../../../assets/images/faq/duitnow3.png";
import step4 from "../../../../../assets/images/faq/duitnow4.png";

// const mySwal = withReactContent(Swal);

const DuitnowGuide = () => {

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const [duitnowUrl, setDuitnowUrl] = useState("");

    useEffect(() => {
        if (location.state && location.state.url) {
            setDuitnowUrl(location.state.url)
        }
        if (location.state && location.state.transactionId) {
            const socket = io({ auth: { transactionId: location.state.transactionId } })
            socket.on("duitnowPayment", (transactionId) => {
                history.push("/thankyou")
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const donwloadQR = (event) => {
    //     event.preventDefault();
    //     fetch(duitnowUrl)
    //         .then((response) => response.blob())
    //         .then((blob) => {
    //             const url = window.URL.createObjectURL(blob);
    //             const a = document.createElement('a');
    //             a.href = url;
    //             a.download = url.substr(url.lastIndexOf('/') + 1);
    //             document.body.appendChild(a);
    //             a.click();
    //             a.remove();
    //         })
    //         .catch((error) => mySwal.fire(t("Error"), t("Some error happen when download the QR code, please screenshot the QR code to make the payment"), "error"));
    // }

    return (
        <div id="setting-page" className="user-faq">
            <div id="setting-main">
                <div id="user-header">
                    <h5><i onClick={e => history.push("/")} className="bi bi-chevron-left me-3"></i> <strong>{t("Duitnow QR code")}</strong></h5>
                </div>

                <div id="user-body">
                    {
                        duitnowUrl ? (
                            <div className="pt-3 pb-3">
                                <h4 className="ps-3">{t("Your Duitnow QR code")}:</h4>
                                <div className="text-center">
                                    <img src={duitnowUrl} alt="duitnow-qr" />
                                </div>
                                <div className="text-center">
                                    {/* <a className="btn btn-primary" href={duitnowUrl} onClick={(e) => donwloadQR(e)}>{t("Download")}</a> */}
                                    <a className="btn btn-primary" href={`${process.env.REACT_APP_API_URL}/api/user/downloadDuitnowQr?url=${duitnowUrl}`} download={`duitnow_${dateFormat(new Date())}_${timeFormat(new Date())}.png`}>{t("Download")}</a>
                                </div>
                            </div>
                        ) : null
                    }

                    <h4 className="pt-3 ps-3">{t("How to pay using Duitnow QR code?")}</h4>

                    <div className="px-3 py-3 d-flex">
                        <div style={{ maxWidth: "30%" }}>
                            <img src={step1} alt="duitnow-guide-step1" className="img-fluid" />
                        </div>
                        <div className="align-content-center ps-2">
                            <h5>01 {t("Launch")}</h5>
                            <p>{t("Launch your preferred Mobile Banking or eWallet app.")}</p>
                        </div>
                    </div>

                    <div className="px-3 py-3 d-flex">
                        <div style={{ maxWidth: "30%" }}>
                            <img src={step2} alt="duitnow-guide-step2" className="img-fluid" />
                        </div>
                        <div className="align-content-center ps-2">
                            <h5>02 {t("Scan QR code from Gallery")}</h5>
                            <p>{t("Choose DuitNow QR from your gallery.")}</p>
                        </div>
                    </div>

                    <div className="px-3 py-3 d-flex">
                        <div style={{ maxWidth: "30%" }}>
                            <img src={step3} alt="duitnow-guide-step3" className="img-fluid" />
                        </div>
                        <div className="align-content-center ps-2">
                            <h5>03 {t("Pay")}</h5>
                            <p>{t("Confirm payment details and pay.")}</p>
                        </div>
                    </div>

                    <div className="px-3 py-3 d-flex">
                        <div style={{ maxWidth: "30%" }}>
                            <img src={step4} alt="duitnow-guide-step4" className="img-fluid" />
                        </div>
                        <div className="align-content-center ps-2">
                            <h5>04 {t("Confirmation")}</h5>
                            <p>{t("Receive payment confirmation.")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DuitnowGuide;